//
// Mixins
// --------------------------------------------------


// Clearfix
// --------
.clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}


// Center-align a block level element
// ----------------------------------
.center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


// Make link color
// ----------------------------------

.a-color(@color) {
  color: @color;

  &:hover,
  &:focus,
  &.active {
    color: mix(@color, black, 80%);
  }
}

.a-color(@color; @color-active) {
  color: @color;

  &:hover,
  &:focus,
  &.active {
    color: @color-active;
  }
}


// Make btn background
// ----------------------------------

.btn-background(@color) {
  background-color: @color;

  &:hover,
  &:focus {
    background-color: mix(@color, white, 80%);
  }

  &:active,
  &.active {
    background-color: mix(@color, black, 85%);
  }
}


// CSS3 PROPERTIES
// --------------------------------------------------

// Border Radius
.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
      -moz-border-radius-topleft: @radius;
          border-top-left-radius: @radius;
}
.border-top-right-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
      -moz-border-radius-topright: @radius;
          border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
      -moz-border-radius-bottomright: @radius;
          border-bottom-right-radius: @radius;
}
.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
      -moz-border-radius-bottomleft: @radius;
          border-bottom-left-radius: @radius;
}

// Single Side Border Radius
.border-top-radius(@radius) {
  .border-top-right-radius(@radius);
  .border-top-left-radius(@radius);
}
.border-right-radius(@radius) {
  .border-top-right-radius(@radius);
  .border-bottom-right-radius(@radius);
}
.border-bottom-radius(@radius) {
  .border-bottom-right-radius(@radius);
  .border-bottom-left-radius(@radius);
}
.border-left-radius(@radius) {
  .border-top-left-radius(@radius);
  .border-bottom-left-radius(@radius);
}

// Drop shadows
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
     -moz-transition: @transition;
       -o-transition: @transition;
          transition: @transition;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
     -moz-transition-delay: @transition-delay;
       -o-transition-delay: @transition-delay;
          transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
  -webkit-transition-duration: @transition-duration;
     -moz-transition-duration: @transition-duration;
       -o-transition-duration: @transition-duration;
          transition-duration: @transition-duration;
}

// Transformations
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
     -moz-transform: rotate(@degrees);
      -ms-transform: rotate(@degrees);
       -o-transform: rotate(@degrees);
          transform: rotate(@degrees);
}
.scale(@ratio) {
  -webkit-transform: scale(@ratio);
     -moz-transform: scale(@ratio);
      -ms-transform: scale(@ratio);
       -o-transform: scale(@ratio);
          transform: scale(@ratio);
}
.translate(@x, @y) {
  -webkit-transform: translate(@x, @y);
     -moz-transform: translate(@x, @y);
      -ms-transform: translate(@x, @y);
       -o-transform: translate(@x, @y);
          transform: translate(@x, @y);
}
.skew(@x, @y) {
  -webkit-transform: skew(@x, @y);
     -moz-transform: skew(@x, @y);
      -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twitter/bootstrap/issues/4885
       -o-transform: skew(@x, @y);
          transform: skew(@x, @y);
  -webkit-backface-visibility: hidden; // See https://github.com/twitter/bootstrap/issues/5319
}
.translate3d(@x, @y, @z) {
  -webkit-transform: translate3d(@x, @y, @z);
     -moz-transform: translate3d(@x, @y, @z);
       -o-transform: translate3d(@x, @y, @z);
          transform: translate3d(@x, @y, @z);
}
.transform-origin(@origin) {
  -webkit-transform-origin: @origin;
     -moz-transform-origin: @origin;
      -ms-transform-origin: @origin;
       -o-transform-origin: @origin;
          transform-origin: @origin;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
.backface-visibility(@visibility){
	-webkit-backface-visibility: @visibility;
	   -moz-backface-visibility: @visibility;
	        backface-visibility: @visibility;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
  -webkit-background-clip: @clip;
     -moz-background-clip: @clip;
          background-clip: @clip;
}

// Background sizing
.background-size(@size) {
  -webkit-background-size: @size;
     -moz-background-size: @size;
       -o-background-size: @size;
          background-size: @size;
}


// Box sizing
.box-sizing(@boxmodel) {
  -webkit-box-sizing: @boxmodel;
     -moz-box-sizing: @boxmodel;
          box-sizing: @boxmodel;
}

// User select
// For selecting text on the page
.user-select(@select) {
  -webkit-user-select: @select;
     -moz-user-select: @select;
      -ms-user-select: @select;
       -o-user-select: @select;
          user-select: @select;
}

// Resize anything
.resizable(@direction) {
  resize: @direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
.content-columns(@columnCount, @columnGap: @gridGutterWidth) {
  -webkit-column-count: @columnCount;
     -moz-column-count: @columnCount;
          column-count: @columnCount;
  -webkit-column-gap: @columnGap;
     -moz-column-gap: @columnGap;
          column-gap: @columnGap;
}

// Optional hyphenation
.hyphens(@mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: @mode;
     -moz-hyphens: @mode;
      -ms-hyphens: @mode;
       -o-hyphens: @mode;
          hyphens: @mode;
}

// Opacity
.opacity(@opacity) {
  opacity: @opacity / 100;
  filter: ~"alpha(opacity=@{opacity})";
}



// BACKGROUNDS
// --------------------------------------------------

// Add an alphatransparency value to any background or border color (via Elyse Holladay)
#translucent {
  .background(@color: @white, @alpha: 1) {
    background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
  }
  .border(@color: @white, @alpha: 1) {
    border-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
    .background-clip(padding-box);
  }
}

// Gradient Bar Colors for buttons and alerts
.gradientBar(@primaryColor, @secondaryColor, @textColor: #fff, @textShadow: 0 -1px 0 rgba(0,0,0,.25)) {
  color: @textColor;
  text-shadow: @textShadow;
  #gradient > .vertical(@primaryColor, @secondaryColor);
  border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}

// Gradients
#gradient {
  .horizontal(@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(to right, @startColor, @endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@startColor),argb(@endColor))); // IE9 and down
  }
  .vertical(@startColor: #555, @endColor: #333) {
    background-color: mix(@startColor, @endColor, 60%);
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down
  }
  .directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(@deg, @startColor, @endColor); // Standard, IE10
  }
  .horizontal-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: mix(@midColor, @endColor, 80%);
    background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(to right, @startColor, @midColor @colorStop, @endColor);
    background-repeat: no-repeat;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down, gets no color-stop at all for proper fallback
  }

  .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: mix(@midColor, @endColor, 80%);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-repeat: no-repeat;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down, gets no color-stop at all for proper fallback
  }
  .radial(@innerColor: #555, @outerColor: #333) {
    background-color: @outerColor;
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@innerColor), to(@outerColor));
    background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -o-radial-gradient(circle, @innerColor, @outerColor);
    background-repeat: no-repeat;
  }
  .striped(@color: #555, @angle: 45deg) {
    background-color: @color;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  }
}
// Reset filters for IE
.reset-filter() {
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}

// Vertical centering
.v-center(){
  &.v-center,
  .v-center {
    display: table;
     width: 100%;
    > div {
      display: table-cell;
      vertical-align: middle;
      margin-top: 0; margin-bottom:0;
      float: none;
    }
    @media (min-width: 768px) {
      &.row {
        &:before,
        &:after {
          display: none;
        }
      }
    }
    @media (max-width: 767px) {
      .box-sizing(border-box);
      padding-left:5px;
      padding-right:5px;
      &.row {

        display: block;
        width: auto;
        &:before,
        &:after {
          display: none;
        }
        > * {
          display: block;
          vertical-align: baseline;
        }
      }
    }
  }  
}
