/**
 * Header 15 stylesheet  
 * */
 .header-15-startup-antiflicker {
    background: #171717 !important;
}
.header-15 {
    padding-top:0;
    padding-bottom:0;
    height:80px;
    z-index:100;
    width: 100%;
    .header-background {
        background: #171717;
    }
    .navbar {
        position: absolute;
        z-index: 2;
        top: 0;
        margin: 0;
        &.navbar-fixed-top {
            top: 0 !important;
        }
        .navbar-form {
            padding: 20px 0 19px 20px;
            position: relative;
        }
        .btn {
            .box-sizing(border-box);
            padding: 8px 28px;
            background-color: transparent;
            border: 2px solid white;
            color: white;
            &:hover, &:focus {
                background-color: white;
                border-color: white;
                color: #171717;
            }
            &:active, &.active {
                background-color: @silver;
                border-color: @silver;
                color: #171717;
            }
        }
        .brand {
            padding-top: 21px;
            .font(25px, white);
            img:first-child {
                float: left;
                margin: -10px 15px 0 0;
            }
        }
        .nav {
            @media (min-width: 992px) {
                position: absolute;
                width: 100%;
                height: 0;
                overflow: visible;
                text-align: center;
                > li {
                    float: none;
                    display: inline-block;
                }
            }
            > li {
                margin-left: 60px;
                &:first-child {
                    margin-left: 0;
                }
                > a {
                    padding: 29px 0 27px;
                    font-weight: normal;
                    .font(16px);
                    .a-color(white, @silver);
                }
                &.active > a {
                    .a-color(@silver, @silver);
                }
            }
        }
        .navbar-toggle {
            background-image: url("@{startup-basePath}common-files/icons/menu-icon@2x.png");
            .background-size(17px 12px);
            margin-top: 26px;
        }
        .navbar-header {
            position: relative;
            z-index: 2;
        }
    }
}
.header-15-sub {
    .v-center;
    min-height: 800px;
    color: white;
    text-align: center;
    position:relative;
    @media (max-width: 480px) {
        min-height: 0;
        padding-bottom: 20px;
    }
    .background {
        background-image:url("@{startup-basePath}common-files/img/NY_001.jpg");
        .opacity(100);
    }
    .hero-unit {
        padding: 0;
        margin: 0;
        background-color: transparent;
        h1 {
            .font(46px, white);
            font-weight: 600;
            border: 8px solid white;
            display: inline-block;
            margin: 315px 0 200px;
            padding: 30px 40px;
        }
        p {
            .font(13px, white);
            font-weight: 800;
            font-family: @font-family-demo;
            letter-spacing: 4px;
        }
        >[class*="fui-"] {
            display: block;
            margin-bottom: 20px;
            .font(22px, white);
        }
        @media (max-width: 480px) {
            h1 {
                .font(20px, white);
                margin: 150px 0 90px;
            }
        }
    }
}
