.navbar {
  left: 0;
  right: 0;
  .brand {
    padding: 23px 28px 24px 0;
    float: left;
  }
  .subnav {
    display: none;
  }
  a {
    -webkit-transition: color 0.25s, background 0.25s;
    -moz-transition: color 0.25s, background 0.25s;
    -o-transition: color 0.25s, background 0.25s;
    transition: color 0.25s, background 0.25s;
  }
  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
  .nav > li {
    float: left;
    > a:hover,  > a:focus {
      background: none;
    }
  }
  .navbar-toggle {
    margin: 26px 0 10px;
    color: white;
    background: none;
    padding: 0;
    width: 30px;
    height: 30px;
    background: url("@{startup-basePath}common-files/icons/menu-icon@2x.png") 50% no-repeat;
    .background-size(17px 12px);
    .border-radius(0);
    &:before {
      display: none;
    }
  }
  .navbar-form .btn {
    height: auto;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .nav > li > a > [class*="fui-"] {
    font-size: 24px;
    margin: -10px 0 0;
    position: relative;
    top: 4px;
  }
  &.navbar-fixed-top {
    .translate3d(0, 0, 0);
    max-width: 1170px;
    margin: 0 auto;
  }
}
.loaded .navbar.navbar-fixed-top {
  .transition(all .33s cubic-bezier(.694, .0482, .335, 1));
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse.collapse {
    display: none !important; 
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-header {
    float: none;
  }
}
.header-19,
.header-16 {
  .navbar-collapse.collapse {
    display: none !important; 
  }
}
@media (max-width: 991px) {
  body > {
    .navbar-collapse {
      width: 230px;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100% !important;
      z-index: 1;
    }
  }
}
@media (min-width: 992px) {
  body > .collapse.navbar-collapse {
    display: none !important;
  }
}

body > {
  .navbar-collapse#header-19,
  .navbar-collapse#header-16 {
    width: 230px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100% !important;
    z-index: 1;
  }
}

body > .navbar-collapse {
  padding: 25px 0;
  overflow: auto;
  background: #222527;
  min-height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  .pull-right,
  .pull-left {
    float: none !important;
  }
  .nav {
    margin: 0 0 10px;
  }
  .nav > li {
    margin: 0;
    > a {
      display: block;
      padding: 12px 35px;
      border-bottom: 2px solid #24292d;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 1px;
      .a-color(#5b5e61, #fff);
      text-transform: uppercase;
      &:hover,
      &:focus {
        background: none;
      }
    }
    &.active > a {
      .a-color(#fff, #fff);
    }
  }
  .subnav {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    li > a {
      display: block;
      margin: 0;
      padding: 7px 35px;
      font-size: 13px;
      font-weight: normal;
      .a-color(#5b5e61, #fff);
      text-transform: capitalize;
    }
  }
  .navbar-form,
  .navbar-search {
    padding: 10px 35px;
    margin: 0;
    &:focus{
      outline: 0 none;
    }
    border-bottom: 0 none;
    border-top: 0 none;
    box-shadow:none;
    .btn, .btn-primary{
      height: 30px;
      line-height: 20px;
      font-size: 15px;
      color: #5b5e61;;
      background: none;
      padding: 0;
      &:hover {
         color: #fff;
      }
    }
  }
}
