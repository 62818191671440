/* styles from flat-ui-free2.0.0-fix.less files */
.swap-text-color(@color) {
  color: @color;
  a&:hover, a&:focus { color: mix(@color, black, 80%); }
}
// Typography
// --------------------------------------------------
body {
  color: @base;
  font-family: @font-family-demo;
  font-size: @font-size-base;
  line-height: @line-height-base;
}

input,
button,
select,
textarea {
  font-family: @font-family-demo;
  font-size: @font-size-base;
}

a {
  color: @link-color;
  text-decoration: none;
  -webkit-transition: color 0.25s, background 0.25s;
  -moz-transition: color 0.25s, background 0.25s;
  -o-transition: color 0.25s, background 0.25s;
  transition: color 0.25s, background 0.25s;
  &:hover {
    color: @link-hover-color;
    text-decoration: none;
  }
}

// Heading sizes
h1, .h1 { font-size: @font-size-h1 /* 64px */ }
h2, .h2 { font-size: @font-size-h2 /* 52px */ }
h3, .h3 { font-size: @font-size-h3 /* 40px */ }
h4, .h4 { font-size: @font-size-h4 /* 29px */ }
h5, .h5 { font-size: @font-size-h5 /* 28px */ }
h6, .h6 { font-size: @font-size-h6 /* 24px */ }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.2em;
}

// H1—H6 specific styling
h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
  margin-bottom: 2px;
}

h3 {
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 2px;
}

h4 {
  font-weight: 500;
  margin-top: 4px;
}

h5 {
  font-weight: 500;
}

h6 {
  font-weight: normal;
}

// Paragraph
p {
  font-size: @font-size-base * 1.286; /* 18px */
  line-height: @line-height-base * 1.399; /* 31px */
}

.lead {
  font-size: @font-size-base * 2; /* 28px */
  line-height: @line-height-base * 1.1895; /* 41px */
  font-weight: 300;
  margin-bottom: 20px;
}

small {
  font-size: 84%; /* 15px */
  line-height: @line-height-base * 1.666; /* 31px */
}

// Blockquote
blockquote {
  position: relative;
  border-left: none;
  padding-left: 19px;
  
  p {
    font-size: @font-size-base * 1.429;
    line-height: @line-height-base * 1.259;
    font-weight: normal;
    margin-bottom: .4em;
  }
  small {
    font-size: @font-size-base * 1.286;
    line-height: @line-height-base * 1.399;
    font-style: italic;
    color: @base;
    
    &:before {
      content: "";
    }
  }
  &:before {
    position: absolute;
    left: 0;
    width: 3px;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: #ebedee;
    content: "";
    .border-radius(2px);
  }
}

.img-rounded {
  .border-radius(5px);
}

.img-comment {
  font-size: @font-size-base * 1.071;
  line-height: @line-height-base;
  font-style: italic;
  margin: 24px 0;
}

// Text colors
.text-primary { .swap-text-color(@brand-secondary) }
.text-success { .swap-text-color(@brand-success) }
.text-danger  { .swap-text-color(@brand-danger) }
.text-warning { .swap-text-color(@brand-warning) }
.text-info    { .swap-text-color(@brand-info) }
.text-inverse { .swap-text-color(@inverse) }
.muted        { .swap-text-color(@gray-light) }

footer{
  background-color:transparent;
}

.input-append input{
  .border-radius(6px 0 0 6px);
}

// background styles
.bg-white {         background-color: #ffffff};
.bg-black {         background-color: #000000};

.bg-turquoise {     background-color: #1abc9c};
.bg-green-sea {     background-color: #16a085};

.bg-emerland {      background-color: #2ecc71};
.bg-nephritis {     background-color: #27ae60};

.bg-peter-river {   background-color: #3498db};
.bg-belize-hole {   background-color: #2980b9};

.bg-amethyst {      background-color: #9b59b6};
.bg-wisteria {      background-color: #8e44ad};

.bg-wet-asphalt {   background-color: #34495e};
.bg-midnight-blue { background-color: #2c3e50};

.bg-sun-flower {    background-color: #f1c40f};
.bg-orange {        background-color: #f39c12};

.bg-carrot {        background-color: #e67e22};
.bg-pumpkin {       background-color: #d35400};

.bg-alizarin {      background-color: #e74c3c};
.bg-pomegranate {   background-color: #c0392b};

.bg-clouds {        background-color: #ecf0f1};
.bg-silver {        background-color: #bdc3c7};

.bg-concrete {      background-color: #95a5a6};
.bg-asbestos {      background-color: #7f8c8d};


.bg-base {          background-color: #34495e};
.bg-firm {          background-color: #1abc9c};
.bg-success {       background-color: #2ecc71};
.bg-danger {        background-color: #e74c3c};
.bg-warning {       background-color: #f1c40f};
.bg-info {          background-color: #3498db};


// border styles
.bordered { border-style: solid; }

.bordered.bg-turquoise {     border-color: @green-sea};
.bordered.bg-emerland {      border-color: @nephritis};
.bordered.bg-peter-river {   border-color: @belize-hole};
.bordered.bg-amethyst {      border-color: @wisteria};
.bordered.bg-wet-asphalt {   border-color: @midnight-blue};
.bordered.bg-sun-flower {    border-color: @orange};
.bordered.bg-carrot {        border-color: @pumpkin};
.bordered.bg-alizarin {      border-color: @pomegranate};
.bordered.bg-clouds {        border-color: @silver};
.bordered.bg-concrete {      border-color: @asbestos};

// common styles
html,
body {
  height: 100%;
}
body {
  overflow-x:hidden;
  color: @asbestos;
  line-height: 1.29;
  @media (max-width: 767px) {
    padding-right: 0;
    padding-left: 0;
  }
}

li {
  line-height: 20px;
}

.btn {
  .border-radius(6px);
  line-height: 22px;
  -webkit-transition: color 0.25s, background 0.25s;
  -moz-transition: color 0.25s, background 0.25s;
  -o-transition: color 0.25s, background 0.25s;
  transition: color 0.25s, background 0.25s;
  &.btn-large {
    font-size: 17px;
    padding: 12px 18px;
  }
}

.btn > [class^="fui-"] {
  margin-left: 4px;
  margin-right: 4px;
  line-height: inherit;
  display: inline-block;
  vertical-align: top;
}

.input-group-btn .btn {
  background: #bdc3c7;
  line-height: 9px 12px 10px;
  line-height: 22px;
  border: 0;
  margin: 0;
  color: #fff;
  margin-left: 0 !important;
  padding-left: 10px;
  padding-right: 12px;
  &:hover {
    background: #cacfd2;
  }
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  outline: none;
}

.form-group {
  margin-bottom: 10px;
}

label {
  line-height: 20px;
}

.carousel {
  line-height: 1;
}

.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
  color: inherit;
}

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

.page-wrapper {
  position: relative;
  z-index: 2;
  background: #fff;
  overflow: hidden;
  min-height: 100%;
}
.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loaded .page-wrapper,
.loaded .header-antiflicker,
.loaded body > header .header-background {
  .translate3d(0, 0, 0);
  .transition(all .33s cubic-bezier(.694, .0482, .335, 1));
}
.loaded.nav-visible {
  .page-wrapper,
  .navbar.navbar-fixed-top,
  .header-antiflicker,
  body > header .header-background {
    .translate3d(-230px, 0, 0);
  }
}

.loaded.no-csstransitions {
  .page-wrapper,
  .header-antiflicker {
    left: 0;
  }
  &.nav-visible {
    .page-wrapper,
    .navbar.navbar-fixed-top,
    .header-antiflicker {
      left: -230px !important;
      right: auto;
    }
  }
}

.carousel-indicators {
  width: 100%;
}

[class^="fui-"] {
    display: inline-block;
    vertical-align: middle;
}

body > section,
header,
footer {
  position: relative;
  .box-sizing(border-box);
  @media (max-width: 767px) {
    padding-right: 5px;
    padding-left: 5px;
  }
}

img {
  width: auto\9;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: @font-family-demo;
  color: #2c3e50;
}

h1 {
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

// force best img interpolation in Chrome
img {
  -webkit-backface-visibility: hidden;
}


textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input{
  font-family: @font-family-demo;
  .font(16px);
}

a, button{
  &:hover, &:focus, &:active{
    outline: none;
  }
}

#bgVideo {
  overflow: hidden;
  > video {
    position: absolute !important;
  }
}