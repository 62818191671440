@startup-basePath: "../../startup/";
@import '../../startup/flat-ui/less/variables.less';
@import '../../startup/flat-ui/less/mixins.less';
@import '../../startup/common-files/less/mixins.less';
@import '../../startup/common-files/less/common.less';
@import '../../startup/common-files/less/helper.less';
@import '../../startup/common-files/less/navbar.less';
@import '../../startup/ui-kit/ui-kit-header/less/header-15.less';
@import '../../startup/ui-kit/ui-kit-projects/less/project-common.less';
@import '../../startup/ui-kit/ui-kit-projects/less/project-2.less';
@import '../../startup/ui-kit/ui-kit-content/less/content-13.less';

.header-15-sub {
	.background {
	  background-image: url("../../images/header-bg.jpg")
	}
	
}

.header-15-sub .hero-unit h1 {
	background: rgba(31,31,84,0.5);
}


.projects-2 .project-bbe {
	background-image: url("../../images/blue-beard-entertainment.jpg")
}

.projects-2 .project-jbg {
	background-image: url("../../images/jellybean-games.jpg")
}

.projects-2 .project-bhe {
  background-image: url("../../images/beinghonestwithmyex.png");
}

.projects-2 .project-wri {
	background-image: url("../../images/writing.jpg")
}

.projects-2 .project-ati {
	background-image: url("../../images/all-that-is.jpg");
	.container .hero-unit {
		background-color: rgba(0, 0, 0, 0.6);
		}
}
.projects-2 .project-tv {
	background-image: url("../../images/tv.jpg")
}

.projects-2 .project-lwm {
	background-image: url("../../images/letswriteamovie.jpg")
}

.header-15 {
	background-color: rgba(0, 0, 0, 0);
}

.header-15 {
	height: 0px;
}

@media all {
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-header {
    float: none;
  }
}
 
 @media all {
 	body > .collapse.navbar-collapse {
    display: none !important; }
 }
 
@media all {
  .header-15 .navbar .nav {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: visible;
    text-align: center;
  }
  .header-15 .navbar .nav > li {
    float: none;
    display: inline-block;
  }
}


@media (max-width: 21991px) {
  body .navbar-collapse {
    width: 230px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100% !important;
    z-index: 1;
  }
}
@media (min-width: 11992px) {
  body > .collapse.navbar-collapse {
    display: none !important;
  }
}

.projects-2 a{
  position:absolute; 
  width:100%;
  height:215px;
  top:0;
  left: 0;
  z-index: 2;
  background-image: url('empty.gif');
}	

.projects-2 .container {
	position: relative;
	width: 100%;
	height: 100%;
	top:0;
  left: 0;
}

.projects-2 > * {
  height: 215px;
  padding-top: 0px;
  padding-bottom: 0px;
}


@media (max-width: 480px) {
  .header-15-sub {
    min-height: 0;
    padding-bottom: 815px;
  }
}

.bg-turquoise {
  background-color: #000000;
}


.header-15 .navbar .navbar-toggle{
	background-size: 30px;
}

.navbar .navbar-toggle {
  
}

.loaded.nav-visible .page-wrapper,
.loaded.nav-visible .navbar.navbar-fixed-top,
.loaded.nav-visible .header-antiflicker,
.loaded.nav-visible body > header .header-background {
  -webkit-transform: translate3d(-300px, 0, 0);
  -moz-transform: translate3d(-300px, 0, 0);
  -o-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
}

.loaded.no-csstransitions.nav-visible .page-wrapper,
.loaded.no-csstransitions.nav-visible .navbar.navbar-fixed-top,
.loaded.no-csstransitions.nav-visible .header-antiflicker {
  left: -300px !important;
  right: auto;
}

body .navbar-collapse#header-19,
body .navbar-collapse#header-16 {
  width: 300px;
}
/* @media (max-width: 991px) {
  body .navbar-collapse {
    width: 300px;
  }
} */
@media (max-width: 21991px) {
  body .navbar-collapse {
    width: 300px;
} }

@media (max-width: 480px) {
	.header-15 .navbar .navbar-toggle {
  background: url("../../startup/common-files/icons/menu-iconblack@2x.png") 50% no-repeat;
 }
}

@media (min-width: 481px) {
	.header-15 .navbar .navbar-toggle {
  background: url("../../startup/common-files/icons/menu-icon@2x.png") 50% no-repeat;
 }
}

@media (max-width: 480px) {
.projects-2 > * {
  height: 80px;
  padding-top: 0px;
  padding-bottom: 0px;
}
}

@media (max-width: 480px) {
  .header-15-sub {
    min-height: 0;
    padding-bottom: 500px;
  }
}



.hero-unit a {
    color: #FFFFFF;
}



.page-wrapper {
    min-height: inherit;
}


@media (min-width: 480px) {
.header-15-sub .hero-unit h1{
    width: 465px;
    height: 125px;
}

.header-15-sub .hero-unit h1:hover {
    font-size: 0;
}

.header-15-sub .hero-unit h1:hover:after {
    content: attr(data-btn-alt);
    font-size: 46px;
    
}
}