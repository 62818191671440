/**
 * Content 13 stylesheet  
 * */
 .content-13 {
    position:relative;
    &.subscribe-form {
        padding-top: 40px;
        padding-bottom: 40px;
        color: #a1a9b1;
        .col-sm-4 {
            text-align: right;
            @media (max-width: 767px) {
                width: auto;
                display: inline-block;
                float: right;
                text-align: center;
            }
            @media (max-width:668px) {
                display: block;
                float: none;
            }
        }
        form {
            margin: 0;
            position: relative;
            .col-sm-8 {
                @media (max-width: 767px) {
                    width: 65%;
                    display: inline-block;
                    float: left;
                }
                @media (max-width:668px) {
                    width: auto;
                    display: block;
                    float: none;
                    text-align: center;
                }
            }
        }
        input[type="text"], input[type="email"] {
            width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;
            .line-height(60px);
            .font(50px, white);
            font-family: @font-family-demo;
            font-weight: 500;
            letter-spacing: -1px;
            .placeholder(white);
            &::-webkit-input-placeholder {
                font-weight: 500;
                letter-spacing: -1px;
            }
            &:-moz-placeholder {
                font-weight: 500;
                letter-spacing: -1px;
            }
            &::-moz-placeholder {
                font-weight: 500;
                letter-spacing: -1px;
            }
            &:-ms-input-placeholder {
                font-weight: 500;
                letter-spacing: -1px;
            }
        }
        .ani-placeholder {
            .box-sizing(border-box);
            position: absolute;
            padding-left: 6px;
            .line-height(60px);
            .font(50px, white);
            font-family: @font-family-demo;
        }
        .btn {
            margin-top: 8px;
            width: 220px;
            max-width: 100%;
            &.btn-primary {
                background: @btn-primary-active-bg;
                &:hover, &:focus {
                    background: #24deb4;
                }
                &:active, &.active {
                    background: #138871;
                }
            }
        }
        @media (max-width: 480px) {
            input[type="text"], input[type="email"], .ani-placeholder {
                width: 100%;
                margin-bottom: 33px;
                .line-height(27px);
                .font(25px, white);
                font-family: @font-family-demo;
                text-align: center;
            }
        }
        @media (max-width: 688px) {
            input[type="text"], input[type="email"], .ani-placeholder {
                width: 100%;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
}
